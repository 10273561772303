<template>
  <div>
    <div class="search-bar">
      <a-form-model ref="searchRef"
                    :model="searchForm"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol">
        <div class="flex justify-between items-center">
          <div class="flex items-center flex-1">
            <div>页面名称：</div>
            <a-input allowClear style="width:300px;"
                       v-model="searchForm.title"
                       placeholder="页面名称"></a-input>
            <a-button class="ml-8" @click="search" type="primary" html-type="submit">搜索</a-button>
          </div>
          <a-button class="mr-8" @click="handlerAdd">新增</a-button>
        </div>
      </a-form-model>
    </div>

    <base-table rowKey="id"
                :columnsData="columns"
                :tableData="tableData"
                :addHeight1="-35"
                :total="total"
                :getListFunc="initData"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #toolbar>
        <h4 class="text-lg font-bold">Web广告页</h4>
      </template>

      <template #operation="{record}">
        <a-button class="p-0 mr-4" type="link"
                  @click="handlerQrcode(record)">二维码</a-button>
        <a-button class="p-0" type="link"
                  @click="handlerDetail(record)">详情</a-button>
        <!-- <a-button class="p-0 ml-4" type="link" 
                  @click="handlerDelete(record)">删除</a-button> -->
      </template>
    </base-table>

    <a-modal v-model="showQRCode"
      width="300px"
      :footer="null"
      @cancel="closeQRCode">
      <div class="mr-4">{{activeRecord && activeRecord.title}}</div>
      <div style="text-align:center;padding:10px;">
        <div id="qrcode_img"
             ref="qrcode_img"></div>
      </div>
      <div>{{activeUrl}}</div>
    </a-modal>
  </div>
</template>

<script>
import { cutQuery } from '@/utils/ossImg'
import QRCode from 'qrcodejs2'
import config from '@/utils/config'

import {
  getWebAdList,
} from "@/api/web-ad.js"

export default {
  components: {

  },
  data () {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      searchForm: {
        title: "",
        page: 1,
        page_count: 20,
      },
      total: 0,

      columns: [ 
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'center',
          width: 90,
        },{
          title: '标题',
          dataIndex: 'title',
          align: 'center',
          // width: 90,
        },
        {
          title: '添加时间',
          dataIndex: 'add_time',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 150,
          slots: { customRender: 'operation' },
          align: 'center'
        },
      ],

      tableData: [],

      showQRCode: false,
      activeRecord: null,
      activeUrl: null,
    }
  },
  async mounted () {
    await this.initData()
  },
  methods: {
    cutQuery,

    async initData() {
      const { data, code } = await getWebAdList(this.searchForm)
      if (code == 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    search(){
      this.searchForm.page = 1
      this.total = 0
      this.initData()
    },
    resetForm() {
      this.$refs.searchRef.resetFields();
      this.initData();
    },

    handlerDelete (record) {
      // 
      console.log(record)
    },
    handlerQrcode(record){
      const url = config.HLL_WXACT_DOMAIN + '/#/ad?id='+record.id
      this.activeRecord = record
      this.activeUrl = url
      this.showQRCode = true
      this.$nextTick(() => {
        const qrdiv = document.getElementById('qrcode_img')
        new QRCode(qrdiv, {
          width: 500,  // 二维码宽度 
          height: 500, // 二维码高度
          text: url,
          correctLevel: QRCode.CorrectLevel.L
        })
      })
    },
    closeQRCode() {
      this.$refs.qrcode_img.innerHTML = '';
    },
    handlerAdd(){
      const newPage = this.$router.resolve({
        path: "/web-ad-edit",
        query: { 
          type: "add", 
        },
      })
      window.open(newPage.href, "_blank")
    },
    handlerDetail (record) {
      const newPage = this.$router.resolve({
        path: "/web-ad-edit",
        query: { 
          type: "edit", 
          id: record.id 
        },
      })
      window.open(newPage.href, "_blank")
    },

  },
}
</script>

<style lang="less">
  .goods-search-bar {
    margin-bottom: 16px;
    padding: 15px 24px 0;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    .ant-form-item {
      margin-bottom: 5px;
    }
  }
</style>
<style lang="less" scoped>
  #qrcode_img{
    width: 180px;
    height: 180px;
    margin-bottom: 5px;
    margin: 20px auto;
  }
  #qrcode_img /deep/ img {
    width: 180px !important;
    height: 180px !important;
  }
  /deep/ .ant-form-item {
    margin-bottom: 10px;
  }
</style>